<template>
  <div>
    <CBreadcrumb :items="links" style="margin-top: -28px;"/>
    <CRow>
			<CCol sm="6">
        <!-- <vue-position-sticky :offsetTop="90" sticky-class="myClass"> -->
				<CCard>
					<CCardHeader>
						<strong>PROFILE</strong> PENGUNJUNG
					</CCardHeader>
					<CCardBody>
            <CRow>
              <CCol sm="12">
                <h6>Kartu Identitas</h6>
                <img
                  v-if="!user.ktp"
                  style="height: 320px; width: 100%;"
                  src="@/assets/icons/ktp.png"
                >
                <img
                  v-if="user.ktp"
                  style="height: 320px; width: 100%;"
                  :src="'https://dcvisit.lintasarta.net/api/public/ktp/' + user.ktp"
                >
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol sm="12">
                <h6>Nama Sesuai Kartu Identitas</h6>
                <h5>{{user.name}}</h5>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol sm="12">
                <h6>Alamat Email</h6>
                <h5>{{user.email}}</h5>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol sm="12">
                <h6>No. Handphone</h6>
                <h5>{{user.mobile_phone || 'Belum Diubah.'}}</h5>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <!-- </vue-position-sticky> -->
      </CCol>
      <CCol sm="6">
				<CCard>
					<CCardHeader>
						<strong>NON-DISCLOSURE</strong> AGREEMENT (NDA) - {{user.expired_at ? 'VALID HINGGA ' + user.expired_at : 'BELUM MEMBACA NDA'}}
					</CCardHeader>
					<CCardBody>
            <p>Dengan ini menyatakan bahwa kami sebagai staf/karyawan dari Perusahaaan/Instansi tersebut di atas, memahami atau mematuhi hal berikut ini :
            <br><br><strong>• Wajib menjaga kerahasiaan data dan informasi milik PT. Aplikanusa Lintasarta serta tidak menyalahgunakan baik untuk kepentingan sendiri, kepentingan perusahaan/instansi ataupun pihak lain.
            <br>• Client atau Pengunjung Datacenter tetap terikat pada kebijakan keamanan informasi yang telah dianut oleh perusahaan/instansi asal pelanggan tersebut.
            <br>• Client atau pengunjung Datacenter wajib memakai tanda pengenal yang telah diberikan PT. Aplikanusa Lintasarta pada waktu kunjungan ke Datacenter.
            <br>• Keamanan perangkat lunak pada perangkat Colocation merupakan tanggung jawab client atau pelanggan Datacenter.
            <br>• PT. Aplikanusa Lintasarta bertanggung jawab atas keamanan fisik baik dari segi lokasi, rak dan kandang (cage), baik ketika pelanggan atau pengunjung Datacenter sedang tidak ada di Datacenter ataupun berada di Datacenter..
            <br>• Ketika client atau pengunjung Datacenter sedang melakukan konfigurasi, keamanan aset pelanggan adalah tanggung jawab client atau pengunjung Datacenter yang bersangkutan.
            <br>• Client atau pengunjung Datacenter tidak diperbolehkan berinteraksi dengan aset yang dimiliki oleh pelanggan lain kecuali telah memiliki dasar dalam perjanjian kerja sama yang sah.
            <br>• Client yang menggunakan rak bersama (sharing) dengan pelanggan lain , dilarang menggunakan aset yang bukan milik pelanggan yang bersangkutan. Hal ini perlu mendapat perlakuan khusus untuk mencegah kabel terputus atau penempatan aset yang tidak seharusnya dari pelanggan tersebut.
            <br>• Jika client menyimpan dan melakukan transmisi cardholder data, maka tanggung jawab untuk mematuhi persyaratan PCI DSS selama bekerja dan melakukan layanan smarthand services terletak pada sisi pelanggan (client).
            <br>• Sesuai dengan PCI DSS dan ISO 27001, PT. Aplikanusa Lintasarta akan memberlakukan proses pengamanan yang ketat terhadap perlindungan informasi dan menjaga kontinuitas bisnis.
            <br>• Telah membaca & memahami Kebijakan Keamanan Informasi milik PT. Aplikanusa Lintasarta.</strong>
            <br>
            <br>Apabila terjadi pelanggaran maka kami bersedia untuk bertanggung jawab sesuai hukum perundang-undangan yang berlaku.</p>
            <br><br>
            <CRow class="mt-3 text-center">
              <CCol sm="8"></CCol>
              <CCol sm="4" class="text-center">
                <h6>Tertanda,</h6>
                <CButton v-if="!user.signature" size="sm" color="success" @click="sign()">SIGN NDA</CButton>
                <img
                  v-if="user.signature"
                  style="height: 100px; width: 100%;"
                  :src="'https://dcvisit.lintasarta.net/' + user.signature.replace(/\\\//g, '/').substr(15, user.signature.length)"
                >
                <p>{{user.name}}</p>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>  
</template>

<script>
import http from '@/utils/http-common';

export default {
  name: 'PageVisitorDetail',
  data () {
		return {
      id: this.$route.params.id,
      worker_id: this.$route.params.worker_id,
      user: [],
      links: [],
		}
	},
  methods: {
    getUser(id) {
			let self = this;
			return http.get('/users/' + id)
			.then(function (response) {
        // console.log(response.data.user)
        self.user = response.data.user;
        /* response.data.user.forEach((value, index) => {
    			self.user = value;
				}); */
			}).catch(function (error) {
				console.log(error);
			});
		},
    sign() {
      let self = this;
      this.$router.push({ path: '/settings/users/form/' + self.worker_id });
    }
  },
  mounted: function(){
    this.links = [
      {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Pengajuan Kunjungan',
          href: '#/approval/visit-request'
        }, 
        {
          text: 'Detail Pengajuan Kunjungan',
          href: '#/approval/visit-request/' + this.id
        },
        {
          text: 'Data Pengunjung'
        }
      ],
    this.getUser(this.worker_id);
  }
}
</script>